@import url("https://fonts.googleapis.com/css?family=Montserrat:900&text=CheatSheetDaiAokiShareFreeRelatedCheatsheetsCommentsAllCheatsheetsRecommendedBooksYouTube");
@font-face {
  font-family: 'icomoon';
  font-display: swap;
  src: url("/icomoon.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

[class^="fa-"], [class*=" fa-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-display: swap;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.fa-twitter:before {
  content: "\e904";
  color: #555;
  transition: 0.3s; }

.fa-twitter:hover:before {
  color: #1da1f2; }

.fa-twitter-share:before {
  content: "\e904";
  color: #1da1f2;
  transition: 0.3s; }

.fa-facebook:before {
  content: "\e905";
  color: #555;
  transition: 0.3s; }

.fa-facebook:hover:before {
  color: #3b5998; }

.fa-github:before {
  content: "\eab0";
  color: #555;
  transition: 0.3s; }

.fa-github:hover:before {
  color: #000; }

.fa-hatenabookmark:before {
  content: "\e901";
  color: #00a4de; }

.fa-pocket:before {
  content: "\e902";
  color: #ef3f56; }

.fa-feedly:before {
  content: "\e903";
  color: #2bb24c; }
