.x-small {
  font-size: 1.0rem;
}
.small {
  font-size: 1.2rem;
}
.normal {
  font-size: 1.5rem;
}
.large {
  font-size: 1.8rem;
}
.x-large {
  font-size: 2.2rem;
}
.xx-large {
  font-size: 2.5rem;
}
.xxx-large {
  font-size: 2.8rem;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.line-through {
  text-decoration: line-through;
}

.inline-code {
  display: inline-block;
  background-color: rgb(238, 238, 238);
  border-radius: 4px;
  padding: 1px 3px;
}

.inline-link {
  color: #0366d6;
  padding-left: 10px;
  margin-left: 10px;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    transform: rotate(-45deg);
    height: 7px;
    width: 7px;
    border-top: solid 2px #0366d6;
    border-left: solid 2px #0366d6;
  }
}

.hidden {
  text-indent:100%;
  white-space:nowrap;
  overflow:hidden;
  height: 0;
}

.hidescrollbar {
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.wrap {
  white-space: pre-wrap;
  word-wrap:break-word;
}

.nowrap {
  white-space: nowrap;
}

.ellipsis {
  display: block;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blue {
  color: #0366d6;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}
