html {
  font-size: 62.5% !important;
}
body {
  font-family: "ヒラギノ丸ゴ Pro W4","ヒラギノ丸ゴ Pro","Hiragino Maru Gothic Pro","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","HG丸ｺﾞｼｯｸM-PRO","HGMaruGothicMPRO";
  font-size: 1.5rem !important;
  font-feature-settings: "kern", "liga", "clig", "calt";
  text-rendering: optimizeSpeed;
  letter-spacing: 0.9px;
  color: #345;
  background-color: #f1f3f5;
}
