@import url(https://fonts.googleapis.com/css?family=Montserrat:900&text=CheatSheetDaiAokiShareFreeRelatedCheatsheetsCommentsAllCheatsheetsRecommendedBooksYouTube);
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
 */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline; }

body {
  line-height: 1.6; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: inherit; }
  a:hover {
    transition: 0.3s; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

*, *:before, *:after {
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

@charset "UTF-8";
html {
  font-size: 62.5% !important; }

body {
  font-family: "ヒラギノ丸ゴ Pro W4","ヒラギノ丸ゴ Pro","Hiragino Maru Gothic Pro","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","HG丸ｺﾞｼｯｸM-PRO","HGMaruGothicMPRO";
  font-size: 1.5rem !important;
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
          font-feature-settings: "kern", "liga", "clig", "calt";
  text-rendering: optimizeSpeed;
  letter-spacing: 0.9px;
  color: #345;
  background-color: #f1f3f5; }

@font-face {
  font-family: 'icomoon';
  font-display: swap;
  src: url("/icomoon.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

[class^="fa-"], [class*=" fa-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-display: swap;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.fa-twitter:before {
  content: "\e904";
  color: #555;
  transition: 0.3s; }

.fa-twitter:hover:before {
  color: #1da1f2; }

.fa-twitter-share:before {
  content: "\e904";
  color: #1da1f2;
  transition: 0.3s; }

.fa-facebook:before {
  content: "\e905";
  color: #555;
  transition: 0.3s; }

.fa-facebook:hover:before {
  color: #3b5998; }

.fa-github:before {
  content: "\eab0";
  color: #555;
  transition: 0.3s; }

.fa-github:hover:before {
  color: #000; }

.fa-hatenabookmark:before {
  content: "\e901";
  color: #00a4de; }

.fa-pocket:before {
  content: "\e902";
  color: #ef3f56; }

.fa-feedly:before {
  content: "\e903";
  color: #2bb24c; }

.x-small {
  font-size: 1.0rem; }

.small {
  font-size: 1.2rem; }

.normal {
  font-size: 1.5rem; }

.large {
  font-size: 1.8rem; }

.x-large {
  font-size: 2.2rem; }

.xx-large {
  font-size: 2.5rem; }

.xxx-large {
  font-size: 2.8rem; }

.bold {
  font-weight: bold; }

.italic {
  font-style: italic; }

.line-through {
  text-decoration: line-through; }

.inline-code {
  display: inline-block;
  background-color: #eeeeee;
  border-radius: 4px;
  padding: 1px 3px; }

.inline-link {
  color: #0366d6;
  padding-left: 10px;
  margin-left: 10px;
  position: relative; }
  .inline-link:hover {
    text-decoration: underline; }
  .inline-link:before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    height: 7px;
    width: 7px;
    border-top: solid 2px #0366d6;
    border-left: solid 2px #0366d6; }

.hidden {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  height: 0; }

.hidescrollbar {
  -ms-overflow-style: none; }
  .hidescrollbar::-webkit-scrollbar {
    display: none; }

.wrap {
  white-space: pre-wrap;
  word-wrap: break-word; }

.nowrap {
  white-space: nowrap; }

.ellipsis {
  display: block;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.blue {
  color: #0366d6; }

.hover-underline:hover {
  text-decoration: underline; }

/*

Visual Studio-like style based on original C# coloring by Jason Diamond <jason@diamond.name>

*/
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #efefef;
  border: solid 1px #ccc;
  color: black; }

.hljs-comment,
.hljs-quote,
.hljs-variable {
  color: #008000; }

.hljs-keyword,
.hljs-selector-tag,
.hljs-built_in,
.hljs-name,
.hljs-tag {
  color: #00f; }

.hljs-string,
.hljs-title,
.hljs-section,
.hljs-attribute,
.hljs-literal,
.hljs-template-tag,
.hljs-template-variable,
.hljs-type,
.hljs-addition {
  color: #a31515; }

.hljs-deletion,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-meta {
  color: #2b91af; }

.hljs-doctag {
  color: #808080; }

.hljs-attr {
  color: #f00; }

.hljs-symbol,
.hljs-bullet,
.hljs-link {
  color: #00b0e8; }

.hljs-emphasis {
  font-style: italic; }

.hljs-strong {
  font-weight: bold; }

